<template>
  <el-dialog
      title="添加婚纱"
      width="55%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" ref="form">
      <el-row>
        <el-col :span="9">
          <el-form-item label="婚纱尺寸" prop="clothesSize">
            <el-select v-model="form.clothesSize" placeholder="婚纱尺寸" clearable class="searchParam">
              <el-option
                  v-for="item in sizeArray"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="婚纱编号" prop="clothesNo">
            <el-input readonly v-model="form.clothesNo" class="searchParam"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <el-form-item label="选择店铺" prop="clothesShop">
            <el-select v-model="form.clothesShop" placeholder="选择店铺" clearable @change="shopChange" class="searchParam">
              <el-option
                  v-for="item in shopIdArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="选择位置" prop="clothesPosition">
            <el-select v-model="form.clothesPosition" placeholder="选择位置" clearable class="searchParam">
              <el-option
                  v-for="item in positionIdArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <el-form-item label="水洗标打印" prop="washingTip">
            <el-switch
                v-model="form.washingTip"
                active-value="1"
                inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="采购日期:" prop="purchaseDate">
            <el-date-picker
                v-model="form.purchaseDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择采购日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addClothesCheck()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "clothes-add",
  data() {
    return {
      form: {
        clothesSize: "",
        clothesNo: "",
        clothesShop: "",
        clothesPosition: "",
        washingTip: 0,
        purchaseDate: "",
        styleId: this.styleInfo.id,
        empId:JSON.parse(localStorage.getItem("emp")).id,
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      shopIdArray:[],
      positionIdArray:[],
      myShopIds: localStorage.getItem("shops"),
      rules: {
        clothesSize: [
          {required: true, message: '请输入婚纱尺寸', trigger: 'change'},
        ],
        clothesNo: [
          {required: true, message: '请输入婚纱编号', trigger: 'blur'},
        ],
        clothesShop: [
          {required: true, message: '请选择所在店铺', trigger: 'change'},
        ],
        clothesPosition: [
          {required: true, message: '请选择所在位置', trigger: 'change'},
        ],
        washingTip: [
          {required: true, message: '是否已打印水洗标', trigger: 'change'},
        ],
        purchaseDate: [
          {required: true, message: '采购日期', trigger: 'change'},
        ],

      },
      dialogState: this.state,
      sizeArray: [],
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    styleInfo: {
      type: Object,
      required: true,
    }
  },
  created() {
    this.queryClothesNo();
    this.queryShopIdArr();
    this.queryClothesSize()
  },
  methods: {
    //检查订单校验
    addClothesCheck: function () {
      console.log(this.form)
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.addClothes();
        }
      })
    },
    //添加店铺
    addClothes: function () {
      let value = "是否添加该婚纱?"
      this.$confirm(value, '添加婚纱:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/clothes/saveClothes",
          params: this.form
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("添加成功!")
            let self = this;
            setTimeout(function () {
              self.close()
            }, 1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //查询婚纱编号
    queryClothesNo(){
      this.$axios({
        method: "get",
        url: "/clothes/queryClothesNoByStyleId",
        params: {
          styleId: this.styleInfo.id,
          tenantCrop: this.form.tenantCrop
        }
      }).then(response => {
        this.form.clothesNo = response.data.data
      })
    },
    //查询店铺
    queryShopIdArr(){
      this.$selectUtils.shopIdsIsValid().then(response=>{
        // this.shopIdArray=JSON.parse(response.data.data)

        this.shopIdArray =JSON.parse(response.data.data);
        let localShopIds=this.myShopIds.split(",")

        this.form.clothesShop=localShopIds.includes("59")?59:Number(localShopIds[0]);
        this.shopChange(this.form.clothesShop);
      })
    },
    //店铺更改
    shopChange(val){
      if (val===""){
        this.positionIdArray=[];
        this.form.clothesPosition="";
      }else {
        this.$selectUtils.queryPositionIdsByShopId(val).then(response=>{
          this.positionIdArray=JSON.parse(response.data.data)
          this.positionIdArray.forEach(s => {
            if (s.name.indexOf("新款入库区") != -1) {
              this.form.clothesPosition = s.value
              return
            }
          })
          // if (localStorage.getItem("tenantCrop") == '2a31c23a-c178-441614928053489') {
          //   if (this.form.clothesShop == 59) {
          //     this.positionIdArray.forEach(s => {
          //       if (s.name.indexOf("新款入库区域") != -1) {
          //         this.form.clothesPosition = s.value
          //         return
          //       }
          //     })
          //   } else {
          //     this.positionIdArray.forEach(s => {
          //       if (s.name.indexOf("收件待定") != -1) {
          //         this.form.clothesPosition = s.value
          //         return
          //       }
          //     })
          //   }
          // }
        })
      }
    },
    // 查询尺寸
    queryClothesSize() {
      this.$selectUtils.queryClothesSizeIds().then(response => {
        this.sizeArray = JSON.parse(response.data.data)
        this.sizeArray.forEach(s => {
          if (s.name==='F') {
            this.form.clothesSize = s.name
            return
          }
        })
      })
    },
    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>
.searchParam{
  width: 220px;
}
</style>